import { FlexBox } from '../../Forms/Forms.styles'
import { Title } from '../../../components'
import styled from 'styled-components'
import { mobileMax } from '../../../constants'
import { FormSignup } from './FormSignup'
import React from 'react'

const Signup = () => {
  return (
    <FlexBox>
      <StyledForms2>
        <Flex>
          <Title name="Registrarse"></Title>
        </Flex>
        <FormSignup></FormSignup>
        <img className="logo" src="./LOGO-AZUL.svg" />
      </StyledForms2>
    </FlexBox>
  )
}

export default Signup

const Flex = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
        color: rgb(37, 99, 235);
        transition: all 0.2s ease;

        &:hover {
            font-weight: 500;
            cursor: pointer;
            border-bottom: 1px solid;
            transform: translateY(-1px);
        }
    }
`

const StyledForms2 = styled.div`
    background-color: rgb(255, 255, 255);
    width: 30%;
    border-radius: 16px;
    border: 1px solid #e5e7eb;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin: 10px;
    padding: 12px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    }

    @media ${mobileMax} {
        text-align: center;
        width: 95%;
        padding: 1.5rem;
    }

    input, select {
        outline: none;
        font-size: 14px;
        font-weight: 400;
        border-radius: 8px;
        border: 1px solid rgb(209, 213, 219);
        padding: 0 15px;
        height: 42px;
        margin: 8px 0;
        width: 100%;
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
        
        &:focus {
            border-color: rgb(37, 99, 235);
            box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.2);
        }
    }

    label {
        font-size: 0.875rem;
        font-weight: 500;
        color: #2e2e2e;
        margin-bottom: 0.25rem;
        align-self: self-start;
    }

    h1, h2 {
        font-weight: 600;
        margin: 10px;
        color: #111827;
    }

    img {
        width: 30%;
        margin: 20px;
        transition: transform 0.2s ease;
        
        &:hover {
            transform: scale(1.05);
        }
    }
`
