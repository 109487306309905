import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { mailValidation, passwordValidation } from '../../../utils/inputValidation'
import { Input, InputError } from '../../Forms'
import PropTypes from 'prop-types'
import { mobileMax, config } from '../../../constants'
import styled from 'styled-components'
import { TitleWithLine } from '../../../components'
import { Link } from 'react-router-dom'
import useForm2 from '../../../hooks/useUser'
import googleService from '../../../services/googleService.js'
// import { useDispatch } from 'react-redux'

export const FormLogin = () => {
  const [error, setError] = useState(false)
  const [loginExitoso, setLoginExitoso] = useState(false)
  // const dispatch = useDispatch()
  const methods = useForm({ shouldUnregister: false })
  const usuario2 = useForm2({
    mail: '',
    password: ''
  })

  const onSubmit = methods.handleSubmit(async (data) => {
    usuario2.changeValue(data)

    try {
      await googleService.login(data.mail, data.password)
      setError({ estado: false, mensaje: '' })
      setLoginExitoso(true)
      // googleService.checkUser(dispatch)
      window.location = config.url.LOCAL_URL
    } catch (e) {
      setError(true)
    }
  })

  // const mandarEmail = async (email) => {
  //   try {
  //     // mandamos email
  //     // si se mando pasamos a la siguiente pagina
  //     await login.mandarEmailRecover()
  //   } catch (e) {
  //     console.log('Email inexistente')
  //   }
  // }

  // const onSubmit = methods.handleSubmit(data => {
  //
  // })

  // Para que cada vez que se desmonte la componente se recuperen con los valores anteriores.

  useEffect(() => {
    // methods.setValue('origen', viaje.getValue().origen)
    // methods.setValue('destino', viaje.getValue().destino)
    // methods.setValue('pasajeros', viaje.getValue().pasajeros)
    // methods.setValue('fecha', viaje.getValue().fecha)
  }, [])

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={(e) => e.preventDefault()} noValidate className="container">
        <Input {...mailValidation} />
        <Input {...passwordValidation} />

        <button onClick={onSubmit} className="login-button">
          Ingresar
        </button>
        {loginExitoso && <p className="success-message">Registro exitoso</p>}
        {error === true ? <InputError message={'Email o Contraseña incorrectos.'}></InputError> : ''}
      </StyledForm>
      <ForgotPasswordLink>
        <Link to="/lostPassword">Olvidaste tu contraseña?</Link>
      </ForgotPasswordLink>
      <TitleWithLine frase={'O continua con '}></TitleWithLine>
      <BotonesContainer>
        <button onClick={() => googleService.redirectToGoogleSSO()} className="google-button">
          <GoogleIcon />
          <span>Sign in with Google</span>
        </button>

        <RegisterLink>
          <span>¿No tenés cuenta? </span>
          <Link to="/registrarse">Registrate</Link>
        </RegisterLink>
      </BotonesContainer>
    </FormProvider>
  )
}

FormLogin.propTypes = {
  success: PropTypes.boolean,
  handleSuccess: PropTypes.func,
  handleNext: PropTypes.func,
  viaje: PropTypes.object
}

const GoogleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1711 8.36788H17.5V8.33329H10V11.6666H14.6422C13.9272 13.6063 12.1133 15 10 15C7.23858 15 5 12.7614 5 9.99996C5 7.23854 7.23858 5 10 5C11.2744 5 12.4383 5.48871 13.3369 6.28375L15.7194 3.90125C14.1717 2.46258 12.1786 1.66663 10 1.66663C5.39771 1.66663 1.66667 5.39767 1.66667 9.99996C1.66667 14.6022 5.39771 18.3333 10 18.3333C14.6023 18.3333 18.3333 14.6022 18.3333 9.99996C18.3333 9.44113 18.2756 8.89592 18.1711 8.36788Z"
      fill="#FFC107"
    />
    <path
      d="M2.62793 6.12121L5.36585 8.12913C6.10418 6.29496 7.90085 5 10.0001 5C11.2745 5 12.4384 5.48871 13.337 6.28375L15.7195 3.90125C14.1718 2.46258 12.1787 1.66663 10.0001 1.66663C6.83751 1.66663 4.12543 3.47371 2.62793 6.12121Z"
      fill="#FF3D00"
    />
    <path
      d="M9.9999 18.3334C12.1249 18.3334 14.0718 17.5683 15.6045 16.1775L13.0062 13.9867C12.1374 14.6434 11.0833 15.0009 9.9999 15C7.89685 15 6.08947 13.6179 5.36947 11.6892L2.58789 13.783C4.07372 16.4775 6.81205 18.3334 9.9999 18.3334Z"
      fill="#4CAF50"
    />
    <path
      d="M18.1711 8.36793H17.5V8.33334H10V11.6667H14.6422C14.2987 12.5892 13.7145 13.3976 12.9644 14.0055L12.9658 14.0045L15.564 16.1953C15.4006 16.3434 18.3333 14.1667 18.3333 10.0001C18.3333 9.44122 18.2756 8.89601 18.1711 8.36793Z"
      fill="#1976D2"
    />
  </svg>
)

const ForgotPasswordLink = styled.p`
  text-align: center;
  margin-top: 16px;
  
  a {
    color: #4169e1;
    text-decoration: none;
    font-size: 14px;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

const RegisterLink = styled.p`
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  
  span {
    color: #4B5563;
  }
  
  a {
    color: #4169e1;
    font-weight: 500;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

const BotonesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 24px;

  .google-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 44px;
    background-color: white;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    color: #4B5563;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
    
    &:hover {
      background-color: #F9FAFB;
      border-color: #D1D5DB;
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(65, 105, 225, 0.2);
    }
  }
`

const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  @media ${mobileMax} {
    text-align: center;
    
    label {
      padding-left: 0px;
      align-self: center;
    }
  }
  
  div {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .login-button {
    width: 100%;
    height: 44px;
    background-color: #4169e1;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 8px;
    margin-bottom: 8px;
    
    &:hover {
      background-color: #3a5ecc;
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(65, 105, 225, 0.4);
    }
  }
  
  .success-message {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 12px;
    font-weight: 500;
    font-size: 14px;
    color: #10B981;
    background-color: #ECFDF5;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  
  input, select {
    outline: none;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    padding: 0 16px;
    height: 44px;
    width: 100%;
    transition: all 0.2s ease;
    
    &:focus {
      border-color: #4169e1;
      box-shadow: 0 0 0 2px rgba(65, 105, 225, 0.2);
    }
  }
  
  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    margin-bottom: 6px;
    align-self: self-start;
  }
`
